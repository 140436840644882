import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import Layout from 'components/Layout';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import BreadCrumbs from 'components/common/BreadCrumbs';

import { getGaviLayoutFlags } from 'utils/helpers';

import './LdsPage.scss';

const LdsPage: FC<AllLds.Page> = ({
  data: {
    allLds: {
      nodes: [
        {
          title,
          body,
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          openGraphImageUrl,
          showBreadcrumbs,
          tags,
          platform,
          revisionId,
          businessId,
        },
      ],
    },
    siteSettings,
    commonSettings,
    header,
    footer,
  },
  pageContext: { breadCrumbs },
}) => {
  const { GAVIDESIGN, GAVIHEADER } = getGaviLayoutFlags(tags, ['GAVIDESIGN', 'GAVIHEADER']);

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL as string,
    businessId,
    versionId: revisionId,
    platform,
  });

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      isGaviDesign={GAVIDESIGN}
      isGaviHeader={GAVIHEADER}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      <div className="lds-page">
        <Container fluid>
          <h1 className="lds-page__title">{title}</h1>
          {!isCheckingLdsVersion && (
            <DangerouslySetInnerHtml
              className="gs-symptom-section__intro-description"
              html={updatedLdsContent ?? body}
            />
          )}
        </Container>
      </div>
    </Layout>
  );
};

export default LdsPage;

export const query = graphql`
  query LdsPageQuery($link: String, $lang: String) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    allLds(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        title
        body
        showBreadcrumbs
        tags {
          name
        }
        platform
        revisionId
        businessId: businessID
      }
    }
  }
`;
